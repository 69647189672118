.screen a {
    display: contents;
    text-decoration: none;
}
.container-center-horizontal {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    max-height: 100%;
    width: 100vw;
    height: 100vh;
    flex-direction: column;
    flex-wrap: nowrap;
}
.container-center-horizontal > * {
    flex-shrink: 0;
    pointer-events: auto;
}
.g839 {
    display: flex;
    height: 13px;
    left: 7px;
    position: absolute;
    top: 10px;
    width: 16px;
}
.path84 {
    flex: 1;
    margin-bottom: -0.7px;
    margin-left: -0.7px;
    margin-right: -0.7px;
    margin-top: -0.7px;
    object-fit: cover;
    width: 17.55px;
}
.g839.g843{
    left: 0;
    top: 0;
}
.ramaaz-website-homepage-2 {
    align-items: flex-start;
    background-color: #f9f9f9;
    display: flex;
    /*gap: 34%;*/
    height: 100%;
    padding: 30px;
    transition: all 0.2s ease;
    width: 100%;
}
.ramaaz-website-homepage-2:hover {
    /*transform: translate(0, -5px);*/
}
.abs-span:hover {
    transform: translate(5px, 0);
}
.languages.active-no {
    margin-left: -27px;
    display: none;
}
.languages.active-h:hover{
    transition-property: all;
    transition-delay: 10s;
    margin-left: -27px;
    display: block !important;
    background-color: transparent;
}
.mask-group-164 {
    display: flex;
    height: 25px;
    width: 25px;
}
.mask-group-161,
.translate-2,
.g825,
.g827 {
    display: flex;
    flex: 1;
    width: 25px;
}
.g829 {
    align-items: center;
    display: flex;
    flex: 1;
    padding: 0 0.7px;
    width: 25px;
}
.overlap-group {
    height: 24px;
    position: relative;
    width: 24px;
}
.g847 {
    background-image: url("path837.png");
    background-position: 50% 50%;
    background-size: cover;
    height: 6px;
    left: 4px;
    position: absolute;
    top: 4px;
    width: 5px;
}
.g835 {
    background-image: url("path849.png");
    background-position: 50% 50%;
    background-size: cover;
    height: 6px;
    left: 15px;
    position: absolute;
    top: 14px;
    width: 4px;
}
.group-4826 {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    min-height: 58px;
    padding: 0px 0;
    width: 100%;
}
.group-4825 {
    align-items: flex-end;
    display: flex;
    margin-left: 20.43px;
    min-width: 53px;
}
.overlap-group-1 {
    height: 17px;
    margin-bottom: 0;
    position: relative;
    width: 53px;
}
.path-16118 {
    height: 11px;
    left: 4px;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 46px;
}
.ellipse-322 {
    height: 7px;
    left: 0;
    position: absolute;
    object-fit: cover;
    top: 10px;
    width: 7px;
}
.ellipse-323 {
    background-color: #404040;
    border-radius: 3.34px;
    height: 7px;
    left: 47px;
    position: absolute;
    top: 10px;
    width: 7px;
}
.ellipse-323-outline {
    height: 7px;
    left: 47px;
    object-fit: cover;
    position: absolute;
    top: 10px;
    width: 7px;
}
.path-161199 {
    height: 58px;
    margin-right: 59px;
    object-fit: cover;
    width: 338px;
}