.screen a {
    /*display: contents;*/
    /*text-decoration: none;*/
}
.container-center-horizontal {
    /*display: flex;*/
    /*flex-direction: row;*/
    /*justify-content: center;*/
    /*pointer-events: none;*/
    /*width: 100%;*/
    /*height: 100%;*/
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    max-height: 100%;
    width: 100vw;
    height: 100vh;
    flex-direction: column;
    flex-wrap: nowrap;
}
.container-center-horizontal > * {
    flex-shrink: 0;
    pointer-events: auto;
}
.ramaaz-website-homepage-mobile {
    align-items: center;
    background-color: #000000;
    display: flex;
    flex-direction: column;
    height: 932px;
    padding: 151.7px 0;
    width: 430px;
}
.mask-group-161,
.translate-2,
.g825,
.g827 {
    display: flex;
    flex: 1;
    width: 25px;
}
.g829 {
    align-items: center;
    display: flex;
    flex: 1;
    padding: 0 0.7px;
    width: 25px;
}
.ramaaz-website-homepage {
    align-items: center;
    background-color: #000000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding: 151.7px 0;
    width: 100%;
}
.group-4823 {
    display: flex;
    height: 58px;
    margin-top: 239px;
    width: 338px;
}
.group-4818 {
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-top: 0;
    margin-bottom: 0;
    margin-right: 0;
    min-height: 100%;
    width: 338px;
}
.languages.active-no {
    transition-delay: 1s;
    transition-property: all;
    margin-left: -27px;
    display: none;
}
.languages.active-h{
    transition-delay: 1s;
    transition-property: all;
    margin-left: -27px;
    display: block !important;
    background-color: transparent;
}
.abs-span:hover {
    transform: translate(5px, 0);
}
.mask-group-164 {
    display: flex;
    height: 25px;
    width: 25px;
}
.group-4819 {
    display: flex;
    height: 58px;
    margin-top: 156px;
    width: 338px;
}
.overlap-group {
    height: 17px;
    margin-left: 20.43px;
    margin-top: -1px;
    position: relative;
    width: 53px;
}
.path-16118 {
    height: 11px;
    left: 4px;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 46px;
}
.ellipse-322 {
    border: 1px solid;
    /*border-color: var(--white);*/
    border-radius: 3.34px;
    height: 7px;
    left: 0;
    position: absolute;
    top: 10px;
    width: 7px;
}
.path-16119 {
    height: 42px;
    margin-right: 0;
    object-fit: cover;
    width: 338px;
}
.path-16135 {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    color: #555555;
    opacity: 90%;
    /*height: 16px;*/
    letter-spacing: 1px;
    margin-top: 111px;
    /*object-fit: cover;*/
    /*width: 206px;*/
}
.path-16134 {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    color: #555555;
    opacity: 90%;
    letter-spacing: 1px;
    /*height: 17px;*/
    margin-right: 0.28px;
    margin-top: 10px;
    /*object-fit: cover;*/
    /*width: 175px;*/
}
.path2179 {
    height: 41px;
    /*margin-right: 30.97px;*/
    margin-top: 36px;
    object-fit: cover;
    width: 33px;
}