.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.parent {
  overflow: hidden !important;
}
* {
   /*pointer-events: none;*/
 }
.parent > * {
   /*cursor: move;*/
}
.home1 {
  z-index: 1;
  transition: top 0.6s ease-in 0.1s;
  position: absolute;
  overflow: visible;
  top: 0;
  /*height: 100% !important;*/
}
.home1.active-home1 {
  z-index: 1;
  transition: top 1.2s ease-in 0.1s;
  overflow: hidden;
  position: absolute;
  top: 100%;
  height: -webkit-fill-available;
}
.home2 {
  z-index: 10;
  transition: bottom 0.6s ease-in 0.1s;
  bottom: 0%;
  position: absolute;
  overflow: visible;
}
.home2.active-home2 {
  z-index: 1;
  transition: bottom 1.2s ease-in 0.1s;
  position: absolute;
  overflow: hidden;
  bottom: 100%;
}
.home2.active-home22 {
  z-index: 11;
  transition: bottom 0.4s ease-in 0.1s;
  position: absolute;
  overflow: visible;
  bottom: 10%;
}
.home1.active-home11 {
  z-index: 11;
  transition: top 0.4s ease-in 0.1s;
  overflow: visible;
  position: absolute;
  top: 10%;
}
.active-home111 {
  z-index: 11 !important;
  transition: all 0.6s ease-in 0.0s !important;
  position: absolute !important;
  overflow: visible !important;
  top: 90% !important;
}
.active-home222 {
  z-index: 11 !important;
  transition: all 0.6s ease-in 0.0s !important;
  overflow: visible !important;
  position: absolute !important;
  bottom: 90% !important;
}
img {
  max-width: 100%;
  height: auto;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.abs-span{
  display: flex;
  position: absolute;
  z-index: 99999;
  top: 0px;
  left: 0px;
  /*width: 50px;*/
  /*height: 50px;*/
  background-color: transparent;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
